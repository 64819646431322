<template>
  <div
    class="ct-centered-spinner-component"
    :class="{ overlay }"
  >
    <div
      :style="computedStyles"
      class="spinner-content d-flex justify-content-center align-items-center"
    >
      <spinner style="width: 5rem; height: 5rem;" label="Loading..." />
      <div class="spinner-slot">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
/*

# Corptools Centered Spinner
Typically anywhere that we want to show a loading spinner we want it to be centered, so we added
this wrapper for convenience.

Props:
 height: the spinner will reserve this much vertical space. Accepts pixels, rem, etc.
 additional-classes: the div parent of the spinner will adopt these classes.

Example usage:
<ct-centered-spinner height="260px"/>

*/

import Spinner from '@images/ui/spinner.svg'

export default {
  name: 'CtCenteredSpinner',
  components: {
    Spinner,
  },
  props: {
    height: {
      type: String,
      default: '5rem',
    },
    overlay: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    computedStyles() {
      return `height: ${ this.height }`
    },
  },
}
</script>
<style lang="scss">
.ct-centered-spinner-component {
  width: 100%;
  height: 5rem !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  &.overlay {
    background: white;
    z-index: 10000;
  }
  .spinner-content {
    position: absolute;
    //top: 20%;
    .spinner-slot {
      @include ct-text--small;
      position: absolute;
      display: flex;
      text-align: center;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      top: 4rem;
      min-width: 10em;
    }
  }
}
</style>
